.SideCategoryMenu{
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 5px;
    width: 18%;
    box-shadow: 0px 4px 8px rgba(0,0,0,0.4);
    background-color: rgba(0,0,255,0.5);
    z-index: 9;
}

.pcategory1{
    /* color: green; */
}


.SideCategoryMenu p {
    font-size: 0.9rem;
    /* font-weight: 500; */
    color: white;
    border-bottom: 1px solid rgba(255,255,255,0.3);
}



.SideCategoryMenu p:hover{
    /* color: #CB6B37; */
    /* text-decoration: underline; */
    cursor: pointer;
    background-color: white;
    color: rgba(0,0,255);
}


@media screen and (max-width:884px) {
    .SideCategoryMenu{
        display: none;
        /* position: absolute;
        top: 0px;
        right: 0px; */
    }   
}