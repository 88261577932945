/* .HeaderWrap1{
    display: flex;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid lightgray;

}

.HeaderWrap1 img{
    width: 120px;
    height: 50px;
    cursor: pointer;
}

.HeaderWrap1 h1{
    font-size: 2.5rem;
    color:#003366;
    cursor: pointer;
}

.HeaderLogoSpan1{
    color:green;
}

.HeaderCenterWrap1{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:20px;
    margin-right: 100px;
}

.HeaderLanguageWrap1{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:5px;
    cursor: pointer;
}

.HeaderLanguageWrap1:hover{
    color: #CB6B37;
}



.HeaderLanguageWrap1 img{
    width: 30px;
    height: 20px;

}

.HeaderLanguageWrap1 p{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}



.HeaderLang1{
    font-size: 0.8rem;
    font-weight: 700;

}



.ArrowDownIcon1{
    font-size: 0.5rem;
    font-weight: bold;
    margin-top: 3px;
}

.SearchBarWrap1{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40vw;

    border-radius: 10px;
    height: 35px;
    overflow: hidden;
    border: 2px solid #003366;
    padding-left: 10px;
    cursor: pointer;
}

.SearchBarWrap1:hover{
    border:2px solid #CB6B37;
}


.SearchBarWrap1 input{
    width: 92%;
    height: 100%;
    outline: none;
    background-color: none;
    border: none;
    outline: none;
    font-size: 1.05rem;
}

.SearchIconWrap1{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8%;
    background-color: green;
    height: 100%;
}

.SearchIcon1{
    color: white;
    font-size: 1.3rem;
}

.HeaderUserArea1{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.HeaderUserArea1 p{
    font-size: 0.8rem;
    cursor: pointer;
}

.HeaderUserArea1 p:hover{
    color: #CB6B37;
}

.HeaderUserArea1 span{
    font-size: 0.9rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;

    color: rgba(0,0,0,0.8);
    gap:2px;
    cursor: pointer;

}

.HeaderUserArea1 span:hover{
    color: #CB6B37;
}

.HeaderUserAreaIcon1{
    font-size: 0.6rem;
    margin-top:5px;
}

.HeaderCartImgWrap1{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-right: 50px;
    cursor: pointer;
}



.HeaderCartIcon1{
    color:#003366;
    font-size: 1.5rem;
}

.HeaderCartIcon1:hover{
    color: #CB6B37;
}

.HeaderCartImgWrap1 p{
    color:#CB6B37;
    font-size: 0.8rem;
    font-weight: bold;

}


.HeaderWrap2{
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    padding: 20px;
    border-bottom: 1px solid gray;

}

.HeaderWrap2Up{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.FaBurger{
    font-size: 1.3rem;
    color: #003366;
    cursor: pointer;
}

.MobileFlag{
    width: 30px;
    height: 20px;
    cursor: pointer;
}

.MobileLogo{
    width: 60px;
}

.HeaderWrap2Up h2{
    color: #003366;
    font-size: 1.9rem;
    cursor: pointer;
}

.HeaderWrap2Up h2 span{
    color: green;
}

.FaUserCircle{
    font-size: 1.6rem;
    color: #003366;
    cursor: pointer;
}

.FaCartPlus{
    font-size: 1.6rem;
    color: #003366;
    cursor: pointer;
}

.HeaderWrap2Down{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
}


.SearchBarWrap2{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 45px;
    border-radius: 10px;
    overflow:hidden;
    border:2px solid #003366;

    padding-left:10px;
    margin-top: 5px;
}

.SearchBarWrap2:hover{
    border: 2px solid orange;
}

.SearchBarWrap2 input{
    width: 85%;

    height: 100%;
    border: none;
    outline: none;
    font-size: 1.1rem;


}

.SearchIconWrap2{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
    height: 100%;
    background-color: green;
    cursor: pointer;
}

.SearchIcon2{
    color: white;
}


@media screen and (max-width:884px) {
    .HeaderWrap1{
        display: none;
    }
    .HeaderWrap2{
        display: flex;
    }
  
}


@media screen and (max-width:320px) {
    .FaBurger{
       
    }   
    .MobileFlag{
        width: 25px ;
        height: 20px;
    }
    .HeaderWrap2Up h2{
        font-size: 1.3rem;
    }
}
 */



 /* General Header Styles */
.header {
    width: 100%;
    padding: 20px;
    background-color: whitesmoke;
    /* color: white; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-image: url("../Images/footerbg2.png");
    /* background-size: contain; */
    position: relative;
}

.header::before{
    background-color: rgba(255,255,255, 0.9);
    height: 100%;
    width: 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    /* z-index: 1; */
}

.header__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
}

.HeaderLogoWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.HeaderLogoWrap h4{
    color: rgba(0, 0, 255, 0.7);
}

.header__logo {
    width: 50px;
    cursor: pointer;
    border-radius: 50%;
}

.header__search {
    display: flex;
    align-items: center;
    border: 1px solid rgba(0, 0, 255, 0.5);
    border-radius: 25px;
    overflow: hidden;
    width: 35%;
    max-width: 600px;
    height: 35px;
}

.header__search-input {
    flex: 1;
    padding: 10px 15px;
    font-size: 1rem;
    border: none;
    outline: none;
    border-radius: 25px 0 0 25px;
    height: 100%;
}

.header__search-btn {
    padding: 10px;
    background-color: rgba(0, 0, 255, 0.5);
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 0 25px 25px 0;
    font-size: 1.2rem;
}

.header__search-btn:hover {
    background-color:rgba(0, 0, 255, 0.7);
}

/* Cart Section */
.header__cart {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.header__cart-icon {
    font-size: 1.6rem;
    margin-right: 5px;
    color: rgba(0, 0, 255, 0.5);
}

.header__cart-qty,
.header__cart-amount {
    font-size: 1rem;
    margin-right: 10px;
    color:rgba(0, 0, 255, 0.7);
}

.header__hamburger {
    font-size: 1.8rem;
    cursor: pointer;
    display: none;
    color:rgba(0, 0, 255, 0.5);
    position: absolute;
    right: 5px;
    top: 5px;
}

.header__hamburger:hover {
    color: rgba(0, 0, 255, 0.7);
}

/* Mobile Menu */
/* .header__mobile-menu {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    z-index: 10;
} */

.header__home__icon{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    color: rgba(0, 0, 255, 0.5);
    font-weight: 500;
}

/* Responsive Design */
@media (max-width: 768px) {
    .header{
        padding: 10px 10px;
        padding-top:20px;
    }

    .header__wrapper{
        flex-direction: column;
        gap: 10px;
    }
    .header__logo {
        width: 30px;
    }

    .header__search {
        width: 60%;
    }

    .header__hamburger {
        display: block;
    }

 
}

@media (max-width: 480px) {
    .header__search {
        width: 95%;
        margin-top: 5px;
    }
    
    .header__cart {
        /* display: none; */
    }
    .header__hamburger{
        font-size: 1rem;
    }
}
