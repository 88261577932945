/* Footer General Styles */
.footer {
    background-color: rgba(0, 0, 255, 0.5);
    padding: 3rem 2rem;
    color: rgba(0, 0, 255, 0.5);
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
  }

  .footer h2{
    color: white;
  }

  .footer h3{
    color: white;
  }

  .footer p{
    color:white;
  }

  .footer a{
    color: white;
  }
  
  .footer__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
  }
  
  /* About Section */
  .footer__about h2 {
    font-size: 1.5rem;
  }

  .footer__about img{
    width: 60px;
    height:inherit;
    border-radius: 50%;
  }
  
  .footer__about p {
    font-size: 0.9rem;
    line-height: 1.6;
  }
  
  /* Quick Links & Categories */
  .footer__links h3,
  .footer__categories h3 {
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
  }
  
  .footer__links ul,
  .footer__categories ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer__links ul li,
  .footer__categories ul li {
    margin-bottom: 0.2rem; 
    font-size: 0.9rem;
  }
  
  .footer__links ul li a,
  .footer__categories ul li a {
    text-decoration: none;
    transition: color 0.3s ease;
    cursor: pointer;
  }
  
  .footer__links ul li a:hover,
  .footer__categories ul li a:hover {
    text-decoration: underline;
  }
  
  /* Newsletter */
  .footer__newsletter h3 {
    font-size: 1.2rem;
  }

  .footer__newsletter p{
    font-size: 0.9rem;
  }

  .footer__newsletter img{
    width: 80px;
    height: 80px;
    margin-top: 10px;
  }
  
  .newsletter__form {
    display: flex;
    margin-top: 1rem;
  }
  
  .newsletter__input {
    padding: 0.6rem;
    border: 1px solid rgba(0, 0, 255, 0.5);
    flex: 1;
    border-radius: 5px 0 0 5px;
    outline: none;
  }
  
  .newsletter__button {
    background-color: rgba(0, 0, 255, 0.5);
    color: white;
    border: none;
    padding: 0.6rem 1rem;
    cursor: pointer;
    border-radius: 0 5px 5px 0;
  }
  
  .newsletter__button:hover {
    background-color: rgba(0, 0, 200, 0.7);
  }
  
  /* Social Icons */
  .footer__social h3 {

    font-size: 1.2rem;
  }
  
  .social__icons {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
   
  }
  
  .social__icon {
    font-size: 2rem;
    transition: color 0.3s ease;
    cursor: pointer;
  }
  
  .social__icon:hover {

  }
  
  /* Copyright */
  .footer__copyright {
    text-align: center;
    margin-top: 2rem;
    font-size: 0.9rem;
  }
  
  /* Responsiveness */
  @media (max-width: 768px) {
    .footer__container {
      grid-template-columns: 1fr 1fr;
      gap: 1.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .footer__container {
      grid-template-columns: 1fr;
    }
  
    .newsletter__form {
      flex-direction: column;
    }
  
    .newsletter__input {
      border-radius: 5px;
      margin-bottom: 1rem;
    }
  
    .newsletter__button {
      border-radius: 5px;
    }
  }
  