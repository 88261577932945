.MenuCategories{
    background-color:rgba(0, 0, 255, 0.5);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    
}

.MenuCategories p{
    color: white;
    /* font-weight: bold; */
    font-size: 0.8rem;
    cursor: pointer;
}

.MenuCategories p:hover{
    text-decoration: underline 4px solid;
    /* color:orange */
}



@media screen and (max-width:768px){
    .MenuCategories{
        display: none;
    }
}