/* 

.ProductDetailPageWrap{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;    
 
}

.ProductDetailPageWrapA{
    width: 90%;
}

.ProductDetailPage{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;

}

.ProductDetailPageLeft{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 6%;
    flex-direction: column;
    gap: 10px;
    margin-left: 20px;


}

.ProductDetailPageLeft img{
    width: 60px;
    height: 60px;
    border-radius: 10px;
    border: 1px solid blue;
    cursor: pointer;
}


.ProductDetailPageLeft2{
    display: none;
    align-items: flex-start;
    justify-content: flex-start;
    width: 6%;
    flex-direction: column;
    gap: 10px;
    margin-left: 20px;


}

.ProductDetailPageLeft2 img{
    width: 60px;
    height: 60px;
    border-radius: 10px;
    border: 1px solid blue;
    cursor: pointer;
}

.ProductDetailPageMid{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;

}

.ProductDetailPageMid img{
    width: 100%;
    margin-top: 80px;
}

.ProductDetailPageRight{
    display: flex;      
    flex-direction: column;

    width: 50%;
    margin-top: 30px;
    
}


.QuantityWrap{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.QuantityControl{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 150px;
    height: 50px;

    border-radius: 5px;
    border: 1px solid lightgray;
    margin-top: 10px;
}

.QuantityControl p {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.QuantityControl p:hover{

    background-color: lightgray;
}


.DetailPageUspImg{
    margin-top: 100px;
    width: 100%;
}

.QualityAndCartButton{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;        
    margin-top: 50px    ;    
}

.QualityAndCartButton button{

    padding: 25px;
    width: 45%;
    cursor: pointer;
    background-color: orange;
    border-radius: 5px;
    border: 1px solid orangered;
    font-weight: bold;
    font-size: 1rem;
}

.QualityBadge{
    margin-right: 20px;
}


.GuarranteeWrap{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.GuarranteeWrap p{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.9rem;
    gap:5px;
    font-weight: bold;
}


@media screen and (max-width:884px) {
    .ProductDetailPage{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }   

    .ProductDetailPageLeft{
        display: none;
    }

    .ProductDetailPageLeft2{
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
   
        margin-left: 0px;
        margin-top: 30px;
    }

    .ProductDetailPageMid{
        width: 100%;
    }
    .ProductDetailPageRight{
        width: 95%;
        text-align: center;
    }
    .QuantityWrap{
        width: 100%;
        align-items: center;
    }
    .QualityAndCartButton{
        flex-direction: column;
        width: 100%;
    }
    .QualityAndCartButton button{
        width: 100%;
        margin-bottom: 20px;
    }
    .GuarranteeWrap{
        width: 100%;
        text-align: center;
    }

    .GuarranteeWrap p{
        text-align: center;
        align-items: center;
        justify-content: center;
    }
} */


.ProductDetailPageWrap {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    max-width: 1200px;
    margin: auto;
}

.ProductDetailPage {
    display: flex;
    gap: 20px;
    width: 100%;
    max-width: 100%;
}

.ImageGallery {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 15%;
}

.Thumbnail {
    width: 100%;
    height: auto;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid transparent;
    transition: border-color 0.3s ease;
}

.Thumbnail:hover, .Active {
    border: 2px solid #003366;
}

.ProductImage img {
    width: 100%;
    max-width: 500px;
    border-radius: 10px;
}

.ProductDetails {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.ProductName {
    font-size: 2rem;
    font-weight: 600;
    color: #333;
}

.StockStatus {
    font-size: 1rem;
    color: #888;
}

.AddToCartButton {
    background-color: rgba(0,0,255,0.5);
    color: #fff;
    padding: 12px;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.AddToCartButton:hover {
    background-color: rgba(0,0,255,0.7);
}

.Guarantees {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: #666;
    font-size: 0.95rem;
}

.Guarantees .Icon {
    color: #28a745;
    margin-right: 8px;
}

.DescriptionHeader {
    font-size: 1.3rem;
    color: #003366;
    margin-top: 20px;
    font-weight: bold;
}

.ProductDescription {
    font-size: 1rem;
    color: #444;
    line-height: 1.6;
}

.Loading, .NoProduct {
    text-align: center;
    font-size: 1.5rem;
    color: #666;
}

@media screen and (max-width: 768px) {
    .ProductDetailPage {
        flex-direction: column;
        align-items: center;
    }
    .ProductDetails {
        align-items: center;
        text-align: center;
    }
    .AddToCartButton {
        width: 100%;
    }
}


@media screen and (max-width:428px) {
    .ProductName {
        font-size: 1.5rem;
        font-weight: 600;
       
    }
}