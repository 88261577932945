/* .ProductListPageWrapA{
    display: flex;
    justify-content: flex-start;
            align-items: flex-start;
    width: 100%;
    padding-top: 10px;
}

.ProductListPageWrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.ProductListPageTitleWrap{
    width: 100%;
    padding-left: 50px;

}

.ProductListPageTitle{
    text-align: left;
    padding: 5px;
    cursor: pointer;
    width: 135px;
    border: 1px solid lightgray;
}

.ProductSortContainer{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 8px;
    gap: 8px;
    background-color: #F4F4F4;  
}

.ProductSortContainer input{
    padding: 5px;
    outline: none;
    border: none;   
    border-radius: 5px;
}

.ProductCards{
    display: flex;
    padding: 20px;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center ;
    gap: 50px;
}

.ProductCard{
    width:230px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    
}

.ProductCard img{
    width: 100%;
    height: 250px;
}

.ProductCard p{
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;

}

.ProductCard p:hover{
    color: #C03A04;
}

.ProductCard span{
    font-weight: bold;
    color: #C03A04;

    font-size: 1.1rem;
}

.ButtonAndQtyWrap{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
}

.QtyWrap{
    display: flex;
    flex-direction: column;

}

.QtyWrap p{
    font-weight: 400;
    font-size: 0.7rem   ;
    font-weight: 500;
}

.QtyWrap input{
    padding: 5px;
    width: 50px;
}

.ButtonAndQtyWrap button{
    padding: 7px 40px;
    cursor: pointer;
    background-color: orange;
    border: none ;
    border-radius: 5px;
    font-weight: bold;
}

@media screen and (max-width:428px) {
    .ProductCard{
        flex-direction: row;
        width: 100%;    
        height: 180px;
       
    }
    .ProductCard img{
        width: 50%;
        height: 100%;
    }
    .ProductTextWrap{
        display: flex;
        flex-direction: column;
    }
    .ButtonAndQtyWrap{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .ButtonAndQtyWrap button{
        padding: 10px 20px;
        margin-top: 10px;
    }
}
 */


 /* General Layout */

.ProductListpageContainer{
    display: flex;
}


.product-list-page {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    /* background-color: #f5f5f5; */
}

.category-title {
    font-size: 1.8rem;
    color: #333;
    padding-bottom: 10px;
    border-bottom: 2px solid #e0e0e0;
    text-align: center;
    margin-bottom: 20px;
}

.product-sort {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 10px;
    font-size: 0.9rem;
    color: #555;
}

.product-sort input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
    width: 150px;
    font-size: 0.9rem;
}

/* Product Grid */
.product-grid {
    /* display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    gap: 20px;
    padding-top: 20px; */
    /* background-color: red; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    
}

/* Product Card */
.product-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
    cursor: pointer;
    text-align: center;
    padding: 5px;
    width: 230px;
}

.product-card:hover {
    /* transform: translateY(-5px); */
}

.product-card img {
    width: 100%;
    height: 150px;
    object-fit: contain;
}

.product-details {
    /* padding: 15px; */
}

.product-details p {
    font-size: 0.9rem;
    font-weight: 500;
    color: #333;
    /* color:rgba(0,0,255,0.7); */
    margin: 10px 0 5px;
    transition: color 0.3s;
}

.product-details p:hover {
    /* color: #f48225; */
}

.product-details span {
    font-size: 0.9rem;
    color: rgba(0,0,255,0.7);    
    font-weight: bold;
    margin-bottom: 10px;
    display: inline-block;
}

.product-details button {
    padding: 8px 8px;
    background-color: rgba(0,0,255,0.5);
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 0.8rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-left: 10px;
}

.product-details button:hover {
    background-color: rgba(0,0,255,0.7);
}

/* Loading and No Products Message */
.loading, .no-products {
    width: 100%;
    text-align: center;
    font-size: 1.1rem;
    color: #555;
    padding: 50px 0;
}

/* Responsive Design */
@media (max-width: 428px) {
    .product-list-page{
        padding: 0px;
        padding: 20px 0px;
    }
    .product-card {
        width: 45%;
    }
    .product-grid{
        gap: 5px;
    }
    .product-card img{
        height: 100px;
    }
    .product-details p{
        font-size: 0.8rem;
    }
}

@media (max-width: 480px) {
    .product-sort input {
        width: 100%;
    }
}

