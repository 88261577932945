.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* whats app icon styling */
.WhatsAppIcon{
  position: fixed;
  z-index: 99;
  /* bottom: 50px; */
  /* right: 50px; */
 bottom: 8%;
 right: 2%; 
  width: 70px;
  box-shadow: 0px 4px 5px;
  border-radius: 50%;
  cursor: pointer;
}

/* @media screen and (max-width:768px) {
  .WhatsAppIcon{
      width: 50px;
      bottom: 120px;
      right: 30px;
  }

  
}

@media screen and (max-width:428px) {
  .WhatsAppIcon{
      bottom: 5%;
      right: 5%;
  }
} */



.swal2-popup {
  /* background-color: greenyellow; */
  /* font-size: 1.5vw; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 30%; */
  /* height: 70%; */
  z-index: 9999999999999999999999999999999999999999999999999999999999999999999999999999;
}

.swal2-popup .swal2-title {
  font-family: sans-serif;
  /* font-size: 1.5rem; */
  /* font-size: 4vw; */
  color: blue;   
}

.swal2-popup .swal2-text {
  font-family: sans-serif;
  /* font-size: 0.5rem; */
}
