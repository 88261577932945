/* .CartWrap{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
    gap: 20px;
}

.CartLeft{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75%;
    border:1px solid lightgray;
}

.CartLeftUp{
  
    background-color: lightgray;
    padding: 5px;
    width: 100%;
    font-weight: bold;
}

.CartLeftDown{
    padding: 10px;
    width: 100%;
}

.CartRight{
    display: flex;
    flex-direction: column;
    width: 25%;
    gap:20px;
    
}

.CartRight button{
    color: white;
    background-color: green;
    padding: 13px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.PromoCode{
    display: flex;
    flex-direction: column;
    background-color: #F9F9F9;
    padding: 12px;  
    border: 1px solid lightgray;    
}

.PromoCode p{
    font-weight: bold;
}

.PromoCodeInputWrap{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    gap:20px;
}

.PromoCodeInputWrap input{
    outline: none;
    border: 1px solid lightgray;
    padding: 8px;
    width: 70%;
}

.PromoCodeInputWrap button{
    color:black;
    background-color: lightgray;
    font-weight: bold;
    padding: 8px;
    width: 90px;
}


.CartSummary{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    width: 100%;

    flex-direction: column;
    border: 1px solid lightgray;
    padding-bottom: 20px;
}

.CartSummary h3{
    padding: 8px;
    background-color: lightgray;
    width: 100%;
    font-size: 1rem;
}

.SubTotalWrap{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top:20px;
    padding-left: 10px ;
    padding-right: 10px;
    padding-bottom: 5px;
    width: 100%;
    font-size: 0.9rem;

}

.Line{
    width: 90%;
    background-color: lightgray;
    height: 1px;
    margin: 0 auto;
    margin-top: 30px;
}

.TotalWrap{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 20px;
    font-weight: bold;
    font-size: 1.1rem;
}


.CartItems{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.CartItemsTitles{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.CartItemsTitles p{
    font-weight: bold;
}

.CartItemsLine{
    width: 100%;
    background-color: black;
    height: 1px;
    margin-top: 8px;
}

.CartItem{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
}

.CartItem img{
    width: 100px;
    height: 100px;
    margin-right: 10px;

}


.CartItemImgandTextWrap{
    display: flex;
    width: 250px;


}

.CartItemTextWrap p{
    font-size: 0.8rem;
}

.CartItemQtyWrap{
    display: flex;
    width: 100px;
    background-color: lightgray;
    border-radius: 5px;
    height: 30px;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.CartItemQtyWrap p{
    width: 33%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


.CartItemQtyWrapMobile{
    display: none;
    width: 100px;
    background-color: lightgray;
    border-radius: 5px;
    height: 30px;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.CartItemQtyWrapMobile p{
    width: 33%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.SubTotal{
    display: flex;
}

.SubTotalMobile{
    display: none;
    font-size: 1rem;
    color: orangered;
    font-weight: bold;
}

.UnitPrice{
    display: flex;
}

.ContinueShoppingButton{
    display: block;
}

@media screen and (max-width:884px) {
    .CartWrap{
        flex-direction: column;
    }    
    .CartLeft{
        width: 100%;
    }

    .CartRight{
        width: 100%;
    }

    .CartItem{
        flex-direction: column;
    }
    .CartItemQtyWrap{

    }

    .CartItemQtyWrapMobile{
        display: flex;
    }
    .SubTotal{
     
    }

    .SubTotalMobile{
        display: flex;
    }

    .UnitPrice{
        display: none;
    }
    .ContinueShoppingButton{
        display: none;
    }
  
}

 */



 /* General styling for the container */
.cart-container {
    display: flex;
    gap: 20px;
    padding: 20px;
    flex-wrap: wrap;
    width: 100%;
}

/* Left section for cart items */
.cart-left {
    flex: 1;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cart-left h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
}

.cart-items {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.cart-item {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 15px;
    border-bottom: 1px solid #ddd;
}

.cart-item img {
    width: 100px;
    height: 100px;
    border-radius: 5px;
}

.cart-item-details h3 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 5px;
}

.cart-item-details p {
    margin: 3px 0;
    font-size: 0.9rem;
    color: #555;
}

/* Quantity control */
.quantity-control {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-left: auto;
}

.quantity-control button {
    padding: 5px 10px;
    font-size: 1rem;
    background: #ddd;
    border: none;
    cursor: pointer;
}

.quantity-control span {
    padding: 0 10px;
    font-size: 1rem;
    background: #f4f4f4;
    border-radius: 5px;
}

.subtotal {
    font-weight: bold;
    color: #333;
}

/* Right section for order summary */
.cart-right {
    flex-basis: 300px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.continue-shopping {
    padding: 10px;
    background:rgba(0, 0, 255, 0.5);
    color: #fff;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
}

.promo-code {
    padding: 15px;
    background: #f9f9f9;
    border-radius: 8px;
    border: 1px solid #ddd;
}

.promo-code label {
    font-size: 1rem;
    font-weight: bold;
}

.promo-code-input {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.promo-code-input input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.promo-code-input button {
    padding: 8px 15px;
    background: rgba(0, 0, 255, 0.5);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

/* Summary section styling */
.cart-summary {
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cart-summary h3 {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 15px;
}

.summary-detail {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 1rem;
    font-weight: bold;
}

.checkout-btn {
    width: 100%;
    padding: 15px;
    font-size: 1rem;
    font-weight: bold;
    background: rgba(0, 0, 255, 0.5);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.checkout-btn:hover{
    background-color: rgba(0, 0, 255, 0.7);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .cart-container {
        flex-direction: column;
        padding: 15px;
    }
    .cart-right {
        width: 100%;
    }
    .cart-item {
        flex-direction: column;
        align-items: flex-start;
    }
}
